import {JobData, JobDataPage} from "../type/JobData";
import {Plugin, PluginPage} from "../type/Plugin";
import conf from "../config/conf.json";
import {ScheduleCollection, ScheduleData, SchedulePage} from "../type/ScheduleData";
import {ExecutionData, ExecutionDataPage, ExecutionResult} from "../type/ExecutionData";
import {Paging} from "../type/Paging";
import {pagingToPagingRequest} from "./pagingUtil";
import {Auth, getAuthentication} from "./authenticationUtil";
import {ImportPluginJobArgument} from "../type/ImportPluginJobArgument";
import {WorkerData, WorkerDataCollection, WorkerDataPage} from "../type/WorkerData";
import {getCache} from "./cacheUtil";
import {isEmpty} from "./stringUtil";
import {ExecutionMessageDataPage} from "../type/ExecutionMessageData";
import {AsyncCallbackDataPage} from "../type/AsyncCallbackData";
import {ExecutionStageDataCollection} from "../type/ExecutionStageData";

const API_BASE_URL = conf.JOB_MANAGER_API_URL;

function trimSlashes(str: string) {
	if (!str) return '';
	return str.replace(/^\/|\/$/g, '');
}

function getUrl(endpoint: string) {
	return [trimSlashes(API_BASE_URL), trimSlashes(endpoint)].join('/');
}

function getRequestOptions(method: string = 'GET', data: object | null = null) {
	const auth: Auth | null = getAuthentication();
	return {
		method: method,
		headers: {
			'Content-Type': 'application/json',
			'Authorization': auth === null ? 'unknown' : auth.apiKey
		},
		body: data === null ? null : JSON.stringify(data)
	};
}

function processRequest(url: string, requestOptions?: object): Promise<Response> {
	return fetch(getUrl(url), requestOptions)
		.then((response) => {
			if (!response.ok) {
				if (response.headers.get('Content-Type') === 'application/json') {
					return response
						.json()
						.then((json) => {
							if (json.message) {
								throw new Error(json.message);
							}
							if (json.error) {
								throw new Error(`${response.status} - ${json.error}`);
							}
							throw new Error(`${response.status} - ${response.statusText}`);
						}, () => {
							throw new Error(`${response.status} - ${response.statusText}`);
						});
				} else {
					throw new Error(`${response.status} - ${response.statusText}`);
				}
			}
			return response;
		});
}

function get(url: string): Promise<Response> {
	return processRequest(url, getRequestOptions());
}

function del(url: string): Promise<any> {
	return processRequest(url, getRequestOptions('DELETE'));
}

function post(url: string, data: object | null = null): Promise<any> {
	return processRequest(url, getRequestOptions('POST', data));
}

function put(url: string, data: object | null = null): Promise<any> {
	return processRequest(url, getRequestOptions('PUT', data));
}

function processRequestText(url: string, requestOptions?: object | undefined): Promise<any> {
	return processRequest(url, requestOptions)
		.then((response) => {
			return response.text();
		});
}

export function getText(url: string): Promise<string> {
	return processRequestText(url, getRequestOptions());
}

export function putText(url: string): Promise<string> {
	return processRequestText(url, getRequestOptions('PUT'));
}

function processRequestJson(url: string, requestOptions?: object | undefined): Promise<any> {
	return processRequest(url, requestOptions)
		.then((response) => {
			return response.json();
		});
}

function getJson(url: string, params?: any): Promise<any> {
	if (params) {
		url = `${url}?${new URLSearchParams(params)}`;
	}
	return processRequestJson(url, getRequestOptions());
}

function postJson(url: string, data: object | null = null): Promise<any> {
	return processRequestJson(url, getRequestOptions('POST', data));
}

function putJson(url: string, data: any | null = null): Promise<any> {
	return processRequestJson(url, getRequestOptions('PUT', data));
}

// STATUS

export function loadAppVersion(): Promise<string> {
	return getText('info/version');
}

export function loadSchedulerState(): Promise<boolean> {
	return getJson('scheduler/state');
}

export function updateSchedulerState(enabled: boolean): Promise<boolean> {
	return putJson('scheduler/state', enabled);
}


// JOBS

export function loadJobTypes(): Promise<string[]> {
	return getCache('job-types', () => getJson('job/type'));
}

export function loadJobs(p: Paging): Promise<JobDataPage> {
	return getJson('job', pagingToPagingRequest(p));
}

export function runJobImmediately(jobId: number): Promise<ScheduleData> {
	return postJson(`job/${jobId}/run-immediately`);
}

export function saveJob(data: JobData): Promise<JobData> {
	if (data.id) {
		return putJson(`job/${data.id}`, data);
	} else {
		return postJson('job', data);
	}
}

export function deleteJob(jobId: number): Promise<ScheduleData> {
	return del(`job/${jobId}`);
}

// SCHEDULES

export function loadScheduleTypes(): Promise<string[]> {
	return getCache('schedule-types', () => getJson('schedule/type'));
}

export function loadSchedules(p: Paging): Promise<SchedulePage> {
	return getJson('schedule', pagingToPagingRequest(p));
}

export function loadSchedulesActiveOnly(p: Paging): Promise<SchedulePage> {
	return getJson('schedule/active', pagingToPagingRequest(p));
}

export function loadActiveSchedules(page: number = 0, size: number = 5): Promise<SchedulePage> {
	return getJson('schedule/active', pagingToPagingRequest({page: page, size: size, sorting: []}));
}

export function saveSchedule(data: ScheduleData): Promise<ScheduleData> {
	if (data.id) {
		return putJson(`schedule/${data.id}`, data);
	} else {
		return postJson('schedule', data);
	}
}

export function deleteSchedule(id: number): Promise<any> {
	return del(`schedule/${id}`);
}

// EXECUTIONS

export function loadExecutions(p: Paging, state?: string): Promise<ExecutionDataPage> {
	if (isEmpty(state)) {
		return getJson('execution', pagingToPagingRequest(p));
	} else {
		return getJson(`execution/by-state/${state}`, pagingToPagingRequest(p));
	}
}

export function loadExecutionData(executionId: number): Promise<ExecutionData> {
	return getJson(`execution/${executionId}`);
}

export function loadExecutionStates(): Promise<string[]> {
	return getCache('execution-states', () => getJson('execution/state'));
}

export function loadRunningExecutions(page: number = 0, size: number = 10): Promise<ExecutionDataPage> {
	return getJson('execution/active', pagingToPagingRequest({page: page, size: size, sorting: []}));
}

export function updateExecutionResult(executionId: number, result: ExecutionResult): Promise<any> {
	return put(`execution/${executionId}/result`, result);
}

export function cancelExecution(executionId: number): Promise<any> {
	return put(`execution/${executionId}/cancel`);
}

export function cancelAllExecutions(): Promise<any> {
	return put('execution/active/cancel-all');
}

// MESSAGES

export function loadExecutionMessages(executionId: number, messageType: string | null | undefined, paging: Paging): Promise<ExecutionMessageDataPage> {
	const mt = isEmpty(messageType) ? '' : `/${messageType}`;
	return getJson(`execution/${executionId}/message${mt}`, pagingToPagingRequest(paging));
}

export function loadMessages(paging: Paging): Promise<ExecutionMessageDataPage> {
	return getJson('execution/message', pagingToPagingRequest(paging));
}

// STAGES

export function loadExecutionStages(executionId: number): Promise<ExecutionStageDataCollection> {
	return getJson(`execution/${executionId}/execution-stages`);
}

// WORKERS

export function loadWorkers(p: Paging): Promise<WorkerDataPage> {
	return getJson('worker', pagingToPagingRequest(p));
}

export function loadWorkersDashboard(): Promise<WorkerDataCollection> {
	return getJson('worker/dashboard');
}

export function updateWorker(worker: WorkerData): Promise<any> {
	return put(`worker/${worker.id}`, worker);
}

// IMPORTS

export function loadPlugin(pluginId: number): Promise<Plugin> {
	return getJson(`plugin-import/${pluginId}/info`);
}

export function startSyncPlugins(): Promise<any> {
	return post(`plugin-import/sync-plugins`);
}

export function startPluginImportRegular(pluginId: number): Promise<any> {
	return post(`plugin-import/${pluginId}/regular`);
}

export function startPluginImportCustom(args: ImportPluginJobArgument): Promise<any> {
	return post(`plugin-import/custom`, args);
}

export function startPluginImportProductsFull(pluginId: number): Promise<any> {
	return post(`plugin-import/${pluginId}/product/full`);
}

export function loadPluginExecutions(pluginId: number, p: Paging): Promise<ExecutionDataPage> {
	return getJson(`plugin-import/${pluginId}/executions`, pagingToPagingRequest(p));
}

export function loadPluginActiveSchedules(pluginId: number): Promise<ScheduleCollection> {
	return getJson(`plugin-import/${pluginId}/schedules/active`);
}

export function loadPluginImportTypes(): Promise<string[]> {
	return getCache('plugin-import-commands', () => getJson('plugin-import/command'));
}

export function loadPluginImportModes(): Promise<string[]> {
	return getCache('plugin-import-modes', () => getJson('plugin-import/mode'));
}

export function searchPlugin(p: Paging): Promise<PluginPage> {
	return getJson(`plugin-import/search`, pagingToPagingRequest(p));
}

export function loadPluginLinkToPivo(pluginId: number): Promise<string> {
	return getText(`plugin-import/${pluginId}/link-to-pivo`);
}

// QUEUES

export function getMessageQueueSize(name: string): Promise<string> {
	return getText(`worker/queue/${name}/size`);
}

export function purgeMessageQueue(name: string): Promise<string> {
	return putText(`worker/queue/${name}/purge`);
}

// ASYNC CALLBACKS

export function loadAsyncCallbacks(p: Paging): Promise<AsyncCallbackDataPage> {
	return getJson('async-callback', pagingToPagingRequest(p));
}
