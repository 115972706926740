import {ObjectUtil} from "incomaker-react-ts-commons";

export function formatNumber(n: number, digits = 2) {
	const s = String(n);
	return s.padStart(digits, '0');
}

export function parseDate(d: Date | string | null | undefined): Date | undefined {
	if (!d) return undefined;
	if (typeof d === 'string') {
		return new Date(d);
	}
	return d;
}

export function formatDateForHumans(d: Date | string | null | undefined): string {
	d = parseDate(d);
	if (!d) return "";

	const year = d.getFullYear();
	const month = formatNumber(d.getMonth() + 1);
	const day = formatNumber(d.getDate());
	const hours = formatNumber(d.getHours());
	const minutes = formatNumber(d.getMinutes());
	const seconds = formatNumber(d.getSeconds());
	return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function getDurationMs(d1?: Date | string | null, d2?: Date | string | null): number | null {
	d1 = parseDate(d1);
	d2 = parseDate(d2);
	if (ObjectUtil.isEmpty(d1) || ObjectUtil.isEmpty(d2)) return null;
	try {
		// @ts-ignore
		return d2.getTime() - d1.getTime();
	} catch (e) {
		return null;
	}
}

export function getSinceDurationMs(d1?: Date | string | null): number | null {
	return getDurationMs(d1, new Date());
}
