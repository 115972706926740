import React, {useEffect, useState} from 'react';
import {Container, Nav} from "react-bootstrap";
import Header from "./Header";
import AlertsBox from "./controls/AlertsBox";
import {Auth, getAuthentication, setAuthentication} from "../util/authenticationUtil";
import LogInForm from "./LogInForm";
import DashboardTab from "./navigation/dashboard/DashboardTab";
import JobsTab from "./navigation/jobs/JobsTab";
import SchedulesTab from "./navigation/scheduling/SchedulesTab";
import ExecutionsTab from "./navigation/execution/ExecutionsTab";
import PluginImportTab from "./navigation/plugin-import/PluginImportTab";
import {loadAppVersion} from "../util/restClient";
import WorkersTab from "./navigation/workers/WorkersTab";
import AsyncCallbacksTab from "./navigation/async-callbacks/AsyncCallbacksTab";
import MessagesTab from "./navigation/messages/MessagesTab";
import ExecutionDetailModal from "./controls/execution/detail/ExecutionDetailModal";
import {JmEventManager} from "./JmEventManager";
import {ImportPluginJobArgument} from "../type/ImportPluginJobArgument";
import PluginImportFormModal from "./navigation/plugin-import/PluginImportFormModal";
import Footer from "./Footer";

function App() {
	const [eventManager] = useState<JmEventManager>(new JmEventManager());
	const [activeTab, setActiveTab] = useState<string>('dashboard');

	const [auth, setAuth] = useState<Auth | null>(null);
	const [version, setVersion] = useState<string | undefined | null>(null);

	const [watchingExecutionId, setWatchingExecutionId] = useState<number | undefined>(undefined);
	const [importForm, setImportForm] = useState<ImportPluginJobArgument | undefined>();

	const refreshAuth = () => {
		setAuth(getAuthentication());
	};

	useEffect(refreshAuth, []);

	const loadVersion = () => {
		loadAppVersion()
			.then(
				(v: string) => {
					setVersion(v);
				},
				() => {
					setVersion(null);
				}
			);
	};

	useEffect(loadVersion, []);

	const logIn = (apiKey: string) => {
		setAuthentication({apiKey: apiKey});
		refreshAuth();
	};

	const logOut = () => {
		setAuthentication(null);
		refreshAuth();
	};

	const tabChanged = (t: string | null) => {
		setActiveTab(String(t));
	}

	useEffect(
		() => {
			eventManager.addWatchExecutionListener(setWatchingExecutionId)
			eventManager.addShowImportFormListener(setImportForm)
			return () => {
				eventManager.removeWatchExecutionListener(setWatchingExecutionId)
				eventManager.removeShowImportFormListener(setImportForm)
			}
		},
		[eventManager]
	);

	const renderBody = () => {
		switch (activeTab) {
			case 'dashboard':
				return <DashboardTab eventManager={eventManager}/>;
			case 'jobs':
				return <JobsTab eventManager={eventManager}/>;
			case 'scheduling':
				return <SchedulesTab eventManager={eventManager}/>;
			case 'execution':
				return <ExecutionsTab eventManager={eventManager}/>;
			case 'messages':
				return <MessagesTab eventManager={eventManager}/>;
			case 'workers':
				return <WorkersTab eventManager={eventManager}/>;
			case 'callbacks':
				return <AsyncCallbacksTab eventManager={eventManager}/>;
			case 'plugin-import':
				return <PluginImportTab eventManager={eventManager}/>;
			default:
				return <>No tab selected</>;
		}
	}

	return (
		<Container className="p-3" fluid>
			<Header auth={auth} onLogOut={logOut} eventManager={eventManager}/>
			{
				auth ?
					<>
						<Nav
							variant="tabs"
							defaultActiveKey={activeTab}
							onSelect={tabChanged}
							className="mb-3"
						>
							<Nav.Item>
								<Nav.Link eventKey="dashboard">Dashboard</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="jobs">Jobs</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="scheduling">Schedules</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="execution">Executions</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="messages">Messages</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="workers">Workers</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="callbacks">Callbacks</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="plugin-import">Plugin Import</Nav.Link>
							</Nav.Item>
						</Nav>
						{renderBody()}
						<Footer version={version} eventManager={eventManager}/>
					</>
					:
					<LogInForm eventManager={eventManager} onLogIn={logIn}/>
			}
			{
				watchingExecutionId && (
					<ExecutionDetailModal
						executionId={watchingExecutionId}
						eventManager={eventManager}
						closeModalRequested={() => setWatchingExecutionId(undefined)}
					/>
				)
			}
			{
				importForm && (
					<PluginImportFormModal
						data={importForm}
						eventManager={eventManager}
						onClosed={() => setImportForm(undefined)}
					/>
				)
			}
			<AlertsBox eventManager={eventManager}/>
		</Container>
	);
}

export default App;
