import React from 'react';
import {BsCalendar2RangeFill} from "react-icons/bs";
import {Button} from "react-bootstrap";
import {Auth} from '../util/authenticationUtil';
import SchedulerStateControl from "./controls/SchedulerStateControl";
import {BasicComponentProps} from "../type/BasicComponentProps";

export type HeaderProps = BasicComponentProps & {
	auth: Auth | null;
	onLogOut: () => any;
};

function Header({auth, eventManager, onLogOut}: HeaderProps) {
	return (
		<header className="app-header">
			<div className="d-flex justify-content-between">
				<div>
					<SchedulerStateControl eventManager={eventManager}/>
				</div>
				<div>
					{
						auth ?
							<Button variant="outline-warning" onClick={onLogOut}>Log&nbsp;Out</Button>
							:
							<span className="text-danger">Not Authenticated</span>
					}
				</div>
			</div>
			<div>
				<h1 className="d-flex align-items-center pt-0 mt-0 pb-4">
					<BsCalendar2RangeFill className="me-4"/>
					<div>
						Incomaker Job Manager
					</div>
				</h1>

			</div>
		</header>
	);
}

export default Header;
