import React from 'react';
import {Table} from "react-bootstrap";
import {Schedule, SchedulePage} from "../../../type/ScheduleData";
import Loading from "../../controls/Loading";
import {formatDateForHumans} from "../../../util/dateUtil";
import JobShortInfoControl from "../../controls/JobShortInfoControl";

export type ActiveSchedulesGridProps = {
	schedules: SchedulePage | null;
};

function ActiveSchedulesGrid({schedules}: ActiveSchedulesGridProps) {
	return (
		<div>
			<div className="m-2">Total Active Schedules: {schedules?.totalElements}</div>
			<Table responsive bordered>
				<thead>
					<tr>
						<th>Plugin</th>
						<th>Description</th>
						<th>Job</th>
						<th>Scheduling</th>
						<th>Next Execution</th>
					</tr>
				</thead>
				<tbody>
					{
						(schedules === null) ? <tr>
								<td colSpan={5}><Loading/></td>
							</tr> :
							schedules.content.map((schedule: Schedule) => {
								return (
									<tr key={schedule.data.id}>
										<td>{schedule.data.job.pluginId}</td>
										<td>
											<small>
												<pre>{schedule.data.job.description}</pre>
											</small>
										</td>
										<td><JobShortInfoControl job={schedule.data.job}/></td>
										<td>{schedule.schedulingDescription}</td>
										<td>{formatDateForHumans(schedule.nextExecutionTime)}</td>
									</tr>
								);
							})
					}
				</tbody>
			</Table>
		</div>
	);
}

export default ActiveSchedulesGrid;
