import React from 'react';
import {BasicComponentProps} from "../type/BasicComponentProps";
import {safeTruncate} from "../util/stringUtil";
import HelpWikiLink from "./controls/HelpWikiLink";
import {Spinner} from 'react-bootstrap';

export type FooterProps = BasicComponentProps & {
	version: string | undefined | null;
};

function Footer({version}: FooterProps) {
	return (
		<div className="d-flex align-items-center justify-content-between mt-3 border-top ">
			<div className="d-flex align-items-center">
				<div>Version:</div>
				<div className="px-2">
					{
						version ?
							<strong>{safeTruncate(version, 16)}</strong>
							:
							version === null ?
								<Spinner animation="border" role="status" size="sm">
									<span className="visually-hidden">loading...</span>
								</Spinner>
								:
								<span className="text-danger">Error</span>
					}
				</div>
				<div>Help:</div>
				<HelpWikiLink link="https://wiki.flexigent.cz/index.php/Job_Manager" name="Job Manager"/>
			</div>
			<div className="text-right">
				Incomaker Job Manager &copy; {new Date().getFullYear()}
			</div>
		</div>
	);
}

export default Footer;
