import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {formatDateForHumans} from "../../../../util/dateUtil";
import DurationControl from "../../DurationControl";
import {formatJsonForHumans} from "../../../../util/jsonUtil";
import {ExecutionData} from '../../../../type/ExecutionData';
import SmartTextBox from "../../SmartTextBox";


export type ExecutionDetailInfoControlProps = {
	execution: ExecutionData;
};

function ExecutionDetailInfoControl({execution}: ExecutionDetailInfoControlProps) {
	return (
		<Container className="mb-2">
			<Row>
				<Col>
					<h5>Execution</h5>
					<div>
						<strong>Started:</strong> {formatDateForHumans(execution.startTime)}
					</div>
					<div>
						<strong>Last State Update:</strong> {execution.stateUpdateTime ? formatDateForHumans(execution.stateUpdateTime) : 'Never'}
					</div>
					{
						execution.durationMs && (
							<div>
								<strong>Total Duration:</strong> <DurationControl ms={execution.durationMs}/>
							</div>
						)
					}
					{
						execution.worker && <>
							<h5>Worker</h5>
							<div>
								<strong>Worker:</strong> {execution.worker.name}
							</div>
							<div>
								<strong>Worker Started:</strong> {formatDateForHumans(execution.startWorkerTime)}
							</div>
							<div>
								<strong>Work Duration:</strong> <DurationControl ms={execution.durationWorkerMs}/>
							</div>
						</>
					}
					<h5>Job</h5>
					<div>
						<strong>Description:</strong>
						<SmartTextBox text={execution.schedule.job.description}/>
					</div>
				</Col>
				<Col>
					<h5>Arguments</h5>
					{
						execution.arguments && (
							<pre className="code">{formatJsonForHumans(execution.arguments)}</pre>
						)
					}
				</Col>
			</Row>
			<hr/>
			<Row>
				<Col>
					<h5>Status Text</h5>
					<SmartTextBox text={execution.result.statusText}/>
				</Col>
			</Row>

		</Container>
	);
}

export default ExecutionDetailInfoControl;
