import React, {FormEvent, MouseEvent, useCallback, useEffect, useState} from 'react';
import {Button, Card, Dropdown, Form, Stack} from "react-bootstrap";
import {BasicComponentProps} from "../../../type/BasicComponentProps";
import {
	cancelExecution,
	loadPlugin,
	loadPluginActiveSchedules,
	loadPluginExecutions,
	loadPluginLinkToPivo,
	runJobImmediately,
	startPluginImportProductsFull,
	startPluginImportRegular,
	startSyncPlugins
} from "../../../util/restClient";
import {integerToString, isValidInteger} from "../../../util/numberUtil";
import PluginSearchForm from "./PluginSearchForm";
import {BsArrowRepeat, BsPlugin} from 'react-icons/bs';
import {isEmpty} from "../../../util/stringUtil";
import {getCache, setCache} from "../../../util/cacheUtil";
import {ExecutionDataPage} from "../../../type/ExecutionData";
import {ScheduleCollection} from "../../../type/ScheduleData";
import {DEFAULT_PAGING, Paging} from "../../../type/Paging";
import {Plugin} from "../../../type/Plugin";
import {clone} from "../../../util/objectUtil";
import {formatDateForHumans} from "../../../util/dateUtil";
import DurationControl from "../../controls/DurationControl";
import ExecutionMessagesModalButton from "../../controls/execution/message/ExecutionMessagesModalButton";
import ExecutionStateControl from "../../controls/execution/state/ExecutionStateControl";
import ExecutionDetailModalButton from "../../controls/execution/detail/ExecutionDetailModalButton";
import AdvancedTable from "../../controls/AdvancedTable";
import ExecutionStateProgressControl from '../../controls/execution/state/ExecutionStateProgressControl';
import ExecutionShortInfoControl from "../../controls/execution/short/ExecutionShortInfoControl";
import ActiveStageControl from "../../controls/execution/short/ActiveStageControl";
import HelpWikiLink from "../../controls/HelpWikiLink";

export type PluginImportTabProps = BasicComponentProps & {};

const DEFAULT_PAGING_EXECUTIONS: Paging = clone(DEFAULT_PAGING);
DEFAULT_PAGING_EXECUTIONS.sorting = [{name: "startTime", desc: true}];
DEFAULT_PAGING_EXECUTIONS.size = 10;

function PluginImportTab({eventManager}: PluginImportTabProps) {
	const defaultSearchText = getCache('default-search-text', () => '');
	const [inputText, setInputText] = useState<string>(defaultSearchText);
	const [isInputTextValid, setIsInputTextValid] = useState<boolean>(isValidInteger(inputText));
	const [pluginId, setPluginId] = useState<number | undefined>(isValidInteger(defaultSearchText) ? Number(defaultSearchText) : undefined);
	const [plugin, setPlugin] = useState<Plugin | undefined>(undefined);
	const [linkToPivo, setLinkToPivo] = useState<string | undefined>();
	const [executions, setExecutions] = useState<ExecutionDataPage | undefined>();
	const [executionsPaging, setExecutionsPaging] = useState<Paging>(clone(DEFAULT_PAGING_EXECUTIONS));
	const [schedules, setSchedules] = useState<ScheduleCollection | undefined>();
	const [showSearchForm, setShowSearchForm] = useState<boolean>(false);

	//<editor-fold desc="Commands">

	const syncPlugins = useCallback(
		(e: MouseEvent<HTMLButtonElement>) => {
			e.preventDefault();
			startSyncPlugins()
				.then(
					() => eventManager.success('Plugins synchronized.'),
					(err) => eventManager.err(String(err))
				);
		},
		[eventManager]
	)

	const loadPluginInfo = useCallback(
		() => {
			if (pluginId === undefined || isNaN(pluginId)) {
				setPlugin(undefined);
				return;
			}
			loadPlugin(pluginId)
				.then(
					setPlugin,
					(err) => {
						setPlugin(undefined);
						eventManager.err(String(err));
					}
				);
		},
		[pluginId, eventManager]
	);

	const loadSchedules = useCallback(
		() => {
			if (pluginId === undefined || isNaN(pluginId)) {
				setSchedules(undefined);
				return;
			}
			loadPluginActiveSchedules(pluginId)
				.then(
					setSchedules,
					(err) => {
						setSchedules(undefined);
						eventManager.err(String(err));
					}
				);
		},
		[pluginId, eventManager]
	);

	const loadExecutions = useCallback(
		() => {
			if (pluginId === undefined || isNaN(pluginId)) {
				setExecutions(undefined);
				return;
			}
			loadPluginExecutions(pluginId, executionsPaging)
				.then(
					setExecutions,
					(err) => {
						setExecutions(undefined);
						eventManager.err(String(err));
					}
				);
		},
		[pluginId, executionsPaging, eventManager]
	);

	const loadLinkToPivo = useCallback(
		() => {
			if (pluginId === undefined || isNaN(pluginId)) {
				setLinkToPivo(undefined);
				return;
			}
			loadPluginLinkToPivo(pluginId)
				.then(
					setLinkToPivo,
					(err) => {
						setLinkToPivo(undefined);
						eventManager.err(String(err));
					}
				);
		},
		[eventManager, pluginId]
	);

	const cancel = useCallback(
		(executionId: number) => {
			if (!window.confirm("Really cancel this running execution?")) {
				return;
			}
			cancelExecution(executionId)
				.then(
					() => {
						loadExecutions();
						eventManager.success(`Cancelled execution ${executionId}.`);
					},
					(err) => eventManager.err(String(err))
				);
		},
		[loadExecutions, eventManager]
	);

	const reload = useCallback(
		() => {
			loadExecutions();
			loadSchedules();
		},
		[loadExecutions, loadSchedules]
	);

	const reloadAll = useCallback(
		() => {
			loadLinkToPivo();
			reload();
		},
		[loadLinkToPivo, reload]
	);

	const startImportRegular = useCallback(
		(e: MouseEvent<HTMLButtonElement>) => {
			e.preventDefault();
			if (pluginId === undefined) return;
			startPluginImportRegular(pluginId)
				.then(
					() => {
						reload();
						eventManager.success('Regular plugin import started.')
					},
					(err) => eventManager.err(String(err))
				);
		},
		[reload, eventManager, pluginId]
	);

	const restartJob = useCallback(
		(jobId?: number | null) => {
			if (jobId !== undefined && jobId !== null && !isNaN(jobId))
				runJobImmediately(jobId)
					.then(
						reload,
						(err) => eventManager.err(String(err))
					)
		},
		[reload, eventManager]
	);

	const startImportProductsFull = useCallback(
		(e: MouseEvent<HTMLButtonElement>) => {
			e.preventDefault();
			if (pluginId === undefined) return;
			startPluginImportProductsFull(pluginId)
				.then(
					() => eventManager.success('Plugin refresh products started.'),
					(err) => eventManager.err(String(err))
				);
		},
		[eventManager, pluginId]
	);

	const showCustomImportForm = useCallback(
		(e: MouseEvent<HTMLButtonElement>) => {
			e.preventDefault();
			if (pluginId === undefined) return;
			eventManager.showImportForm({
				vipQueue: false,
				priorityMessage: true,
				workerParam: {pluginId: pluginId, importCommand: 'ImportAll', importMode: 'Auto'}
			});
		},
		[eventManager, pluginId]
	);

	const loadInfoSubmitted = useCallback(
		(e: FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			if (isInputTextValid) {
				const id = Number(inputText);
				if (id === pluginId) {
					reloadAll();
				} else {
					setPluginId(id);
				}
			} else {
				setPluginId(undefined);
			}
		},
		[isInputTextValid, reloadAll, pluginId, inputText]
	);

	const pluginSearchConfirmed = useCallback(
		(pluginId: number) => {
			setShowSearchForm(false);
			setSchedules(undefined);
			setExecutions(undefined);
			setInputText(String(pluginId));
			setPluginId(pluginId);
		},
		[]
	);

	//</editor-fold>

	//<editor-fold desc="Effects">

	useEffect(
		loadExecutions,
		[executionsPaging]
	);

	useEffect(
		() => {
			setIsInputTextValid(isValidInteger(inputText));
		},
		[inputText]
	);

	useEffect(
		loadPluginInfo,
		[pluginId]
	);

	useEffect(
		() => {
			setCache('default-search-text', integerToString(pluginId));
			reloadAll();
			const handle: NodeJS.Timeout = setInterval(reload, 3000);
			return () => {
				window.clearTimeout(handle);
			};
		},
		[pluginId, reloadAll]
	);

	//</editor-fold>

	const executionsHeader = [
		{name: 'id', label: 'ID'},
		{name: 'schedule.job.name', label: 'Job'},
		{name: 'startTime', label: 'Start Time'},
		{name: 'durationMs', label: 'Total Duration'},
		{name: 'executionState', label: 'State'},
		{name: 'progress', label: 'Progress'},
		{name: '', label: 'Active stage'},
		{name: 'errors', label: 'Errors'},
		{name: 'warnings', label: 'Warnings'},
		{name: 'worker.name', label: 'Worker'},
		{name: 'startWorkerTime', label: 'Worker Started'},
		{name: 'durationWorkerMs', label: 'Work Duration'},
		{name: '', label: ''},
	];

	return (
		<div>
			<div className="d-flex">
				<Form onSubmit={loadInfoSubmitted}>
					<Form.Group className="mb-3" controlId="pluginId">
						<Stack direction="horizontal" gap={3} className="flex-wrap">
							<Form.Label className="m-0">Plugin ID:</Form.Label>
							<div>
								<Form.Control
									type="text"
									autoFocus={true}
									value={inputText}
									onChange={(e) => setInputText(e.target.value)}
								/>
							</div>
							<Button variant="secondary" onClick={() => setShowSearchForm(true)}>
								...
							</Button>
							<Button variant="success" type="submit" disabled={!isInputTextValid} className="d-flex align-items-center">
								<BsArrowRepeat className="me-1"/>
								Refresh
							</Button>

							<Dropdown>
								<Dropdown.Toggle
									variant="primary"
									disabled={!isInputTextValid}
								>
									Execute...
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<Dropdown.Item onClick={startImportRegular}>Regular Import</Dropdown.Item>
									<Dropdown.Item onClick={startImportProductsFull}>Full Product Update</Dropdown.Item>
									<Dropdown.Divider/>
									<Dropdown.Item onClick={showCustomImportForm}>Custom Command...</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
							<Button variant="success" className="d-flex align-items-center" onClick={syncPlugins}>
								<BsPlugin className="me-1"/>
								<div>Synchronize Plugins</div>
							</Button>
							<div className="d-flex align-items-center">
								Help:
								<HelpWikiLink link="https://wiki.flexigent.cz/index.php/Category:Feed_Imports" name="Feed Imports"/>
							</div>
						</Stack>
					</Form.Group>
				</Form>
			</div>
			<div>
				<Stack direction="horizontal" className="my-2 border-bottom align-items-start">
					{
						plugin &&
						<div className="pe-3">
							<h2>Plugin {plugin.id}</h2>
							<div>Platform: <strong>{plugin.platform?.name}</strong></div>
							<div>URL: <strong>{plugin.url}</strong></div>
							<div>Account: <strong>{plugin?.account?.name}</strong></div>
							{
								(!isEmpty(linkToPivo)) &&
								<div>
									Run integration validation in <a target="_blank" rel="noreferrer" href={linkToPivo}>P I V O</a>.
								</div>
							}
						</div>
					}
					{
						schedules &&
						<div className="ms-2">
							<h2>Active Schedules</h2>
							<div className="pb-3 d-flex gap-2">
								{
									schedules.length > 0 ?
										schedules.map((schedule, index) => {
											return <Card className="p-2" key={index}>
												<div className="d-flex">
													<strong>Type:</strong>
													<span className="ms-2">{schedule.data.scheduleType}</span>
												</div>
												<div className="d-flex">
													<strong>Scheduling:</strong>
													<span className="ms-2">{schedule.schedulingDescription}</span>
												</div>
												<div className="d-flex">
													<strong>Job Name:</strong>
													<span className="ms-2">{schedule.data.job.name}</span>
												</div>
											</Card>
										})
										: "No active schedules."
								}
							</div>
						</div>
					}
				</Stack>
				{
					executions &&
					<div>
						<h2 className="my-3">Executions</h2>
						<AdvancedTable
							header={executionsHeader}
							onPagingChanged={setExecutionsPaging}
							totalPages={executions ? executions.totalPages : 0}
							totalItems={executions ? executions.totalElements : 0}
							paging={executionsPaging}
						>
							{
								executions.content.map((execution) => {
									return (
										<tr key={execution.id}>
											<td>{execution.id}</td>
											<td><ExecutionShortInfoControl execution={execution}/></td>
											<td className="text-nowrap">{formatDateForHumans(execution.startTime)}</td>
											<td><DurationControl ms={execution.durationMs}/></td>
											<td>
												<ExecutionStateControl state={execution.result.executionState}/>
											</td>
											<td>
												<ExecutionStateProgressControl
													executionState={execution.result.executionState}
													progress={execution.result.progress}
													direction="horizontal"
													gap={1}
												/>
											</td>
											<td>
												{
													execution.activeStage &&
													<ActiveStageControl activeStage={execution.activeStage}/>
												}
											</td>
											<td>
												{
													execution.errors > 0 ?
														<ExecutionMessagesModalButton
															eventManager={eventManager}
															executionId={execution.id}
															defaultMessageType="Error"
															buttonLabel={String(execution.errors)}
															buttonSize="sm"
														/>
														: execution.errors
												}
											</td>
											<td>
												{
													execution.warnings > 0 ?
														<ExecutionMessagesModalButton
															eventManager={eventManager}
															executionId={execution.id}
															defaultMessageType="Warning"
															buttonLabel={String(execution.warnings)}
															buttonSize="sm"
														/>
														: execution.warnings
												}
											</td>
											<td>{execution.worker?.name}</td>
											<td>{formatDateForHumans(execution.startWorkerTime)}</td>
											<td><DurationControl ms={execution.durationWorkerMs}/></td>
											<td>
												<ExecutionDetailModalButton
													buttonSize="sm"
													eventManager={eventManager}
													executionId={execution.id}
												/>
											</td>
										</tr>
									);
								})
							}
						</AdvancedTable>
					</div>
				}
				{
					showSearchForm ?
						<PluginSearchForm
							eventManager={eventManager}
							onCancelled={() => setShowSearchForm(false)}
							onConfirmed={pluginSearchConfirmed}/>
						: <></>
				}
			</div>
		</div>
	);
}

export default PluginImportTab;
